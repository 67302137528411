@import url('https://fonts.cdnfonts.com/css/ds-digital');

@font-face {
    font-family: 'D-DIN';
    src: url('../public/fonts/D-DIN.woff2') format('woff2'),
        url('../public/fonts/D-DIN.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../public/fonts/D-DIN-Italic.woff2') format('woff2'),
        url('../public/fonts/D-DIN-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../public/fonts/D-DIN-Bold.woff2') format('woff2'),
        url('../public/fonts/D-DIN-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../public/fonts/D-DINCondensed.woff2') format('woff2'),
        url('../public/fonts/D-DINCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../public/fonts/D-DINCondensed-Bold.woff2') format('woff2'),
        url('../public/fonts/D-DINCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'D-DIN Exp';
    src: url('../public/fonts/D-DINExp.woff2') format('woff2'),
        url('../public/fonts/D-DINExp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../public/fonts/D-DINExp-Bold.woff2') format('woff2'),
        url('../public/fonts/D-DINExp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../public/fonts/D-DINExp-Italic.woff2') format('woff2'),
        url('../public/fonts/D-DINExp-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DS-Digital';
    src: url('../public/fonts/digital-7_mono-webfont.woff2') format('woff2'),
        url('../public/fonts/digital-7_mono-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@import '~modern-css-reset/src/reset.css';

table {
    border-spacing: 0;
}

:root {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

.full-height {
    height: 100%;
}

a {
    text-decoration: none;
}

button {
    padding: 0;
    margin: 0;
    background-color: inherit;
}

iframe {
    border: none;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }
};

@media screen and (min-width: 769px) {
    .hide-on-laptop {
        display: none !important;
    }
};